<template>
    <div class="card border-0 cursor-pointer" :id="'folder-card-' + this.resource.id"
         draggable="true"
         @dragstart="folderDragStart">
        <div class="card-body">
            <div class="row">
                <div class="col text-center">
                    <h6 class="mb-0 text-primary wrap-ellipses">{{ resource.title }}</h6>
                    <p class="mb-0 bg-light mt-3 p-2">
                        <i class="'text-primary doc-icon fa fa-folder"
                           @drop.stop="folderDrop($event)"
                           @dragenter.stop="folderDragEnter()"
                           @dragleave.stop="folderDragLeave()"
                           @dragover.prevent></i>
                    </p>
                    <div class="row mt-2 px-2">
                        <div class="col-4 px-1">
                            <a @click="$parent.goToResource(resource)" class="btn btn-xs btn-outline-primary w-100"><i class="fa fa-eye"></i></a>
                        </div>
                        <div class="col-4 px-1">
                            <button @click="shareResource(resource)" class="btn btn-xs btn-outline-primary w-100"><i class="fa fa-share-alt"></i></button>
                        </div>
                        <div class="col-4 px-1">
                            <button @click="deleteResource(resource)" class="btn btn-xs btn-outline-primary w-100"><i class="fa fa-trash text-danger"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: ["resource"],
    data: function () {
      return {};
    },
    methods: {
      shareResource(resource) {
        this.$emit('shared', resource);
      },
      deleteResource(resource) {
        this.$emit('deleted', resource);
      },
      folderDragStart(event) {
        event.dataTransfer.setData('text/plain', JSON.stringify({
          id: this.resource.id,
          folder: this.$parent.folder ? this.$parent.folder.folder : 0,
        }));
      },
      folderDragEnter(){
        $('#folder-card-' + this.resource.id).addClass('bg-primary');
      },
      folderDragLeave(){
        $('#folder-card-' + this.resource.id).removeClass('bg-primary');
      },
      folderDrop(event){
        $('#folder-card-' + this.resource.id).removeClass('bg-primary');
        const evtData = JSON.parse(event.dataTransfer.getData('text'));
        if (this.resource.id !== evtData.id){
          this.$axios.post(process.env.VUE_APP_API_URL + "/resources/move",
            {
              target_folder_id: this.resource.id,
              source_id: evtData.id,
            }).then(({ data }) => {
              this.$EventBus.$emit("alert", data);
              this.$EventBus.$emit("resourceAdded");
            });
        }
      },
    },
  };
</script>

<style scoped>
.doc-icon {
    font-size: 36px;
}
</style>
