<template>
  <div class="container-fluid">

    <div class="row">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <h5 class="client-tab-heading">
                  Add Folder
                </h5>
              </div>
              <div class="col text-end">
                <button
                  @click="tabChange('files-tab')"
                  class="btn btn-outline-primary btn-sm me-2"
                >
                  <i class="far fa-align-center me-1"></i>
                  Browse Resources
                </button>

                <button
                  @click="tabChange('upload-tab')"
                  class="btn btn-outline-primary btn-sm"
                >
                  <i class="fas fa-folder me-1"></i>
                  Add Resources
                </button>
              </div>
            </div>

            <hr class="mt-2 mb-4" />

            <div class="row mb-3">
              <div class="col-auto my-auto">
                <input
                  type="text"
                  class="form-control mb-3"
                  :value="'Parent Folder: ' + folder.title"
                  v-if="folder"
                  readonly
                />

                <input
                  type="text"
                  placeholder="Folder Name"
                  class="form-control mb-3"
                  :class="'name' in errors ? 'is-invalid' : ''"
                  v-model="resourceName"
                />

                <div class="custom-control custom-switch my-3">
                  <input
                      type="checkbox"
                      class="custom-control-input me-1"
                      id="shareWithClinic"
                      v-model="shareWithClinic"
                      :checked="shareWithClinic"
                  />
                  <label class="custom-control-label" for="shareWithClinic">Share with Clinic</label>
                </div>

                <button
                  class="btn btn-primary btn-block center-bottom mb-4"
                  @click="addFolder"
                >
                  <i class="far fa-save me-1"></i> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: ["folder"],
  data() {
    return {
      errors: [],
      folderName: "",
      shareWithClinic: true,
      resourceName: ""
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  methods: {
    validateInputs() {
      this.errors = [];
      if (this.resourceName === "") {
        this.errors["name"] = "Folder name cannot be empty.";
      }
    },
    clearInputs() {
      this.resourceName = "";
    },
    addFolder() {
        this.validateInputs();
        if (Object.keys(this.errors).length === 0) {
            this.$axios
            .post(process.env.VUE_APP_API_URL + "/resources/api", {
              title: this.resourceName,
              description: "This is a folder",
              type: "folder",
              visible_to_clinic: this.shareWithClinic,
              clinic_id: this.shareWithClinic ? this.user.clinic_id : null,
              folder: this.folder ? this.folder.id : 0,
            })
            .then(({ data }) => {
              this.clearInputs();
              this.$EventBus.$emit("alert", data);
              this.$EventBus.$emit("resourceAdded");
            });
        }
    },
    tabChange(tab) {
      this.$emit("tabChange", tab);
    }
  },
};
</script>
