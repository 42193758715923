<template>
  <div>
    <div class="container-fluid">
      <div class="row"></div>
        <!-- <div class="col-auto">
          <resource-sidebar :resource="resource"/>
        </div> -->
        <div class="col-md-12">
          <div id="files-tab" class="tab-pane fade show" v-if="tab == 'files-tab'">
            <resource-index @tabChange="tabChange" :user="user" :path="path" :folder.sync="folder"/>
          </div>
          <div id="view-tab" class="tab-pane fade show" v-if="resource != null && tab == 'view-tab'">
            <view-resource @tabChange="tabChange" :user="user" :resource="resource"/>
          </div>
          <div id="upload-tab" class="tab-pane fade show" v-if="tab == 'upload-tab'">
            <upload-files @tabChange="tabChange" :folder="folder"/>
          </div>
          <div id="folder-tab" class="tab-pane fade show" v-if="tab == 'folder-tab'">
            <add-folder @tabChange="tabChange" :folder="folder"/>
          </div>
          <!-- <div id="search-tab" class="tab-pane fade show">
            <advanced-search/>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceSidebar from "./partials/Sidebar";
import ResourceIndex from "./Index";
import UploadFiles from "./partials/UploadFiles";
import ViewResource from "./View";
import AdvancedSearch from "./partials/AdvancedSearch";
import AddFolder from "./partials/AddFolder";

export default {
  props: ["user", "path", "resource"],
  data () {
    return {
      folder: null,
      tab: "files-tab"
    }
  },
  methods: {
    tabChange(tab) {
      console.log('triggered');
      this.tab = tab;
    }
  },
  components: {
    ResourceIndex,
    ResourceSidebar,
    UploadFiles,
    ViewResource,
    AdvancedSearch,
    AddFolder
  },
};
</script>
