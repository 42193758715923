<template>
  <div
    class="modal fade"
    id="shareMultipleResourcesModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTitle">Share Resources</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-4 mb-3">
              <div class="card border-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col text-center selection-box">
                      <table class="table table-sm">
                        <tr>
                          <th>Selected Files</th>
                        </tr>
                        <tr v-for="r in resources" :key="r.id">
                          <td>{{ r.title }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <assign-client
                    @assigned="selectClient"
                    :user="user"
                  ></assign-client>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="clientsToShareWith.length > 0">
            <div class="col">
              <hr />
              <h5>
                Share with Client<span v-if="clientsToShareWith.length > 0"
                  >s ({{ clientsToShareWith.length }})</span
                >:
              </h5>
              <ul class="list-group">
                <li
                  class="list-group-item"
                  v-for="c in clientsToShareWith"
                  :key="c.id"
                >
                  {{ c.name }}
                  <span class="float-end">
                    <button
                      class="btn btn-sm btn-light"
                      @click="removeClientFromList(c)"
                    >
                      <i class="far fa-times text-danger"></i>
                    </button>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="clientsToShareWith.length > 0">
          <button class="btn btn-light" @click="cancelShare">
            <i class="far fa-times me-1"></i>
            Cancel
          </button>
          <button
            class="btn btn-primary"
            @click="shareWithClients"
            :disabled="busy"
          >
            <busy :visible="busy" button>Please wait...</busy>
            <span v-if="!busy">
              <i class="far fa-share me-1"></i>
              Share with {{ clientsToShareWith.length }} client<span
                v-if="clientsToShareWith.length > 0"
                >s</span
              >
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignClient from "./AssignClient";
export default {
  components: { AssignClient },
  props: ["user", "resources"],
  data() {
    return {
      busy: false,
      clientsToShareWith: [],
    };
  },
  methods: {
    selectClient(client) {
      if (
        !this.clientsToShareWith.filter(function (c) {
          return c.id === client.id;
        }).length > 0
      ) {
        this.clientsToShareWith.push(client);
      }
    },
    removeClientFromList(client) {
      this.clientsToShareWith = this.clientsToShareWith.filter(function (c) {
        return c.id !== client.id;
      });
    },
    cancelShare() {
      this.clientsToShareWith = [];
      $("#shareMultipleResourcesModal").modal("hide");
    },
    shareWithClients() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/resources/share-multi", {
          clients: this.clientsToShareWith,
          resources: this.resources,
        })
        .then(({ data }) => {
          this.busy = false;
          this.$EventBus.$emit("alert", data);
          $("#shareMultipleResourcesModal").modal("hide");
        });
    },
  },
  mounted() {
    $("#shareMultipleResourcesModal").modal("show");
    $("#shareMultipleResourcesModal").on("hide.bs.modal", (e) => {
      this.$emit("close");
    });
  },
};
</script>

<style scoped>
.selection-box {
  font-size: 12px;
  max-height: 250px;
  overflow: auto;
}
</style>
