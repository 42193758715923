<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card card-default">
          <div class="card-body bg-white">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <h4 class="mb-0">Resource Preview</h4>
              </div>
              <div class="col-auto my-auto ms-auto">
                  <button
                    class="btn btn-primary"
                    @click="hideViewer()"
                  >
                    Back
                  </button>
                </div>
            </div>
            <div class="row">
              <div class="col d-flex justify-content-center">
                <div v-if="!SupportedViewer()">
                  <p>
                    No supported viewer for this file type, you must download to
                    view.
                  </p>
                </div>
                <iframe
                  v-else-if="SupportedViewer() == 'office'"
                  :src="
                    'https://view.officeapps.live.com/op/embed.aspx?src=' +
                    document
                  "
                  width="100%"
                  height="600px"
                ></iframe>

                <video
                  v-else-if="SupportedViewer() == 'video'"
                  width="80%"
                  controls
                >
                  <source :src="document" type="video/mp4" />
                </video>

                <iframe
                  v-else-if="SupportedViewer() == 'direct'"
                  :src="document"
                  width="100%"
                  height="600px"
                ></iframe>

                <div
                  v-else-if="SupportedViewer() == 'image'"
                ><img :src="document" style="max-height:600px;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["document"],
  data: function () {
    return {
      filetype: null,
    };
  },
  methods: {
    hideViewer() {
      this.$EventBus.$emit("viewDocumentClose");
    },
    setFileType() {
      let regFilter = /(?:\.([^.]+))?$/;
      this.filetype = regFilter.exec(this.document)[1];
    },
    SupportedViewer() {
      let SupportedArray_Office = ["doc", "docx", "xls", "xlsx"];
      let SupportedArray_Video = ["mp4"];
      let SupportedArray_Image = ["jpg", "jpeg", "png"];
      let SupportedArray_Direct = ["pdf"];
      if (SupportedArray_Office.includes(this.filetype)) return "office";
      else if (SupportedArray_Video.includes(this.filetype)) return "video";
      else if (SupportedArray_Image.includes(this.filetype)) return "image";
      else if (SupportedArray_Direct.includes(this.filetype)) return "direct";
      else {
        return null;
      }
    },
  },
  created: function () {
    this.setFileType();
  },
};
</script>