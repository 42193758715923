<template>
  <div
    class="modal fade"
    id="shareResourceModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTitle">Share Resource</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-4 mb-3">
              <div class="card border-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col text-center">
                      <h6 class="mb-0 text-primary wrap-ellipses">
                        {{ resource.title }}
                      </h6>
                      <p
                        v-if="resource.model === 'UrlResource'"
                        class="mb-0 bg-light mt-3 p-2"
                      >
                        <i class="fas fa-link" style="font-size: 36px"></i>
                      </p>
                      <p
                        v-if="resource.model === 'FileResource'"
                        class="mb-0 bg-light mt-3 p-2"
                      >
                        <i class="fas fa-file" style="font-size: 36px"></i>
                      </p>
                      <a
                        :href="'/resources/' + resource.id"
                        class="btn btn-sm btn-outline-primary mt-3 w-100"
                        ><i class="fa fa-eye me-2"></i>View</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="user.id === resource.created_by"
                class="custom-control custom-switch mt-3"
              >
                <input
                  type="checkbox"
                  class="custom-control-input me-1"
                  id="shareWithClinic"
                  v-model="resource.visible_to_clinic"
                  :checked="resource.visible_to_clinic"
                  @change="toggleShareWithClinic"
                />
                <label class="custom-control-label" for="shareWithClinic"
                  >Share with Clinic</label
                >
              </div>
              <div
                class="d-block bg-light px-2 py-1 mb-3"
                v-if="
                  user.id === resource.created_by && resource.visible_to_clinic
                "
              >
                <small>
                  <i class="far fa-info-circle me-1 text-primary"></i>Resource
                  will be visible amongst all practitioners within your clinic.
                </small>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <assign-client
                    :model="resource"
                    @assigned="selectClient"
                    :user="user"
                  ></assign-client>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="clientsToShareWith.length > 0">
            <div class="col">
              <hr />
              <h5>
                Share with Client<span v-if="clientsToShareWith.length > 0"
                  >s ({{ clientsToShareWith.length }})</span
                >:
              </h5>
              <ul class="list-group">
                <li class="list-group-item" v-for="c in clientsToShareWith">
                  {{ c.name }}
                  <span class="float-end">
                    <button
                      class="btn btn-sm btn-light"
                      @click="removeClientFromList(c)"
                    >
                      <i class="far fa-times text-danger"></i>
                    </button>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="clientsToShareWith.length > 0">
          <button class="btn btn-light" @click="cancelShare">
            <i class="far fa-times me-1"></i>
            Cancel
          </button>
          <button
            class="btn btn-primary"
            @click="shareWithClients"
            :disabled="busy"
          >
            <busy :visible="busy" button>Please wait...</busy>
            <span v-if="!busy">
              <i class="far fa-share me-1"></i>
              Share with {{ clientsToShareWith.length }} client<span
                v-if="clientsToShareWith.length > 0"
                >s</span
              >
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignClient from "./AssignClient";
export default {
  components: { AssignClient },
  props: ["user", "resource"],
  data() {
    return {
      busy: false,
      clientsToShareWith: [],
    };
  },
  methods: {
    selectClient(client) {
      //add to list if not already there
      if (
        !this.clientsToShareWith.filter(function (c) {
          return c.id === client.id;
        }).length > 0
      ) {
        this.clientsToShareWith.push(client);
      }
    },
    removeClientFromList(client) {
      this.clientsToShareWith = this.clientsToShareWith.filter(function (c) {
        return c.id !== client.id;
      });
    },
    cancelShare() {
      this.clientsToShareWith = [];
      $("#shareResourceModal").modal("hide");
    },
    shareWithClients() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/resources/share", {
          clients: this.clientsToShareWith,
          resource_id: this.resource.id,
        })
        .then(({ data }) => {
          this.busy = false;
          this.$EventBus.$emit("alert", data);
          this.$EventBus.$emit("sharedWithClient");
          $("#shareResourceModal").modal("hide");
        });
    },
    toggleShareWithClinic() {
      this.resource.clinic_id = this.resource.visible_to_clinic
        ? this.user.clinic.id
        : null;

      this.$axios
        .put(
          process.env.VUE_APP_API_URL + "/resources/api/" + this.resource.id,
          this.resource
        )
        .then(({ data }) => {
          this.busy = false;
          this.$EventBus.$emit("alert", data);
        });
    },
  },
  mounted() {
    $("#shareResourceModal").modal("show");
    $("#shareResourceModal").on("hide.bs.modal", (e) => {
      this.$emit("close");
    });
  },
};
</script>

<style>
</style>
