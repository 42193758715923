<template>

  <div class="container-fluid">

    <div class="row">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <h5 class="client-tab-heading">
                  Add Resources
                </h5>
              </div>
              <div class="col text-end">
                <button
                  @click="tabChange('files-tab')"
                  class="btn btn-outline-primary btn-sm me-2"
                >
                  <i class="far fa-align-center me-1"></i>
                  Browse Resources
                </button>

                <button
                  @click="tabChange('folder-tab')"
                  class="btn btn-outline-primary btn-sm me-2"
                >
                  <i class="fas fa-upload me-1"></i>
                  Add Folder
                </button>
              </div>
            </div>

            <hr class="mt-2 mb-4" />

            <div class="row mb-3">
              <div class="col-auto my-auto">
                <p class="alert alert-primary mb-3">
                  Resources are non-sensitive files or URLs that are sharable with your
                  clients en masse.
                </p>
                <input
                    type="text"
                    class="form-control mb-3"
                    :value="'Parent Folder: ' + folder.title"
                    v-if="folder"
                    readonly
                  />
                <select
                  class="form-control mb-3"
                  :class="'type' in errors ? 'is-invalid' : ''"
                  v-model="resourceType"
                >
                  <option value>Select a resource type</option>
                  <option value="file">Single Resource</option>
                  <option value="bulk">Multiple Resources</option>
                  <option value="url">URL</option>
                </select>

                <input
                  type="text"
                  placeholder="Resource Title"
                  class="form-control mb-3"
                  :class="'title' in errors ? 'is-invalid' : ''"
                  v-model="resourceTitle"
                  v-if="resourceType == 'file' || resourceType == 'url'"
                />

                <textarea
                  type="text"
                  placeholder="Resource description..."
                  class="form-control mb-3"
                  :class="'description' in errors ? 'is-invalid' : ''"
                  rows="3"
                  v-model="resourceDescription"
                  v-if="resourceType == 'file' || resourceType == 'url'"
                ></textarea>

                <div class="row">
                  <div class="col-auto" v-if="fileUploaded">
                    <i class="far fa-check text-primary"></i>
                  </div>
                  <div class="col">
                    <vue-dropzone
                      class="mt-3 mb-3"
                      ref="resourceDropzone"
                      id="resource-dropzone"
                      :options="dropzoneOptions"
                      v-if="resourceType === 'file'"
                      v-on:vdropzone-success="fileUploadSuccess"
                    ></vue-dropzone>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <vue-dropzone
                      class="mt-3 mb-3"
                      ref="resourceDropzoneBulk"
                      id="resource-dropzone2"
                      :options="dropzoneOptionsBulk"
                      v-on:vdropzone-success="fileUploadSuccess"
                      v-on:vdropzone-queue-complete="queueFinished"
                      v-if="resourceType === 'bulk'"
                    ></vue-dropzone>
                  </div>
                </div>

                <input
                  type="text"
                  placeholder="https://www.swandoola.com"
                  class="form-control mb-3"
                  :class="'url' in errors ? 'is-invalid' : ''"
                  v-if="resourceType === 'url'"
                  v-model="resourceUrl"
                />

                <div class="custom-control custom-switch mb-3" v-if="resourceType">
                  <input
                    type="checkbox"
                    class="custom-control-input me-1"
                    id="shareWithClinic"
                    v-model="shareWithClinic"
                    :checked="shareWithClinic != null && shareWithClinic != 0"
                  />
                  <label class="custom-control-label" for="shareWithClinic"
                    >Share with Clinic</label
                  >
                </div>

                <div class="d-block bg-light px-2 py-1 mb-3" v-if="shareWithClinic">
                  <small>
                    <i class="far fa-info-circle me-1 text-primary"></i>Resource will be
                    visible amongst all practitioners within your clinic.
                  </small>
                </div>

                <button
                  v-if="resourceType"
                  class="btn btn-primary btn-block center-bottom"
                  @click="addResource"
                  :disabled="busySaving"
                >
                  <busy :visible="busySaving" button />
                  <i v-if="!busySaving" class="far fa-save me-1"></i> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <text-editor-modal
      v-model="inlineDocument"
      :value="inlineDocument"
      v-if="showTextEditorModal"
      @close="showTextEditorModal = false"
    ></text-editor-modal>
  </div>
  </div>
</template>
<script>
import vue2Dropzone from "vue2-dropzone";
import Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  props: ["folder"],
  data() {
    return {
      errors: [],
      resourceTitle: "",
      resourceDescription: "",
      resourceType: "",
      resourceUrl: "",
      shareWithClinic: true,
      showTextEditorModal: false,
      inlineDocument: null,
      fileUploaded: false,
      busySaving: false
    };
  },
  methods: {
    queueFinished() {
      this.clearInputs();
      this.$EventBus.$emit("resourceAdded");
      this.$EventBus.$emit("alert", { message: "Resources created." });
      this.busySaving = false;
    },
    fileUploadSuccess(file, response) {
      console.log(response.url);
      if (this.resourceType === "bulk") {
        this.resourceTitle = file.name;
        this.resourceDescription = "Add description here.";
      }
      this.fileUploaded = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/resources/api", {
          title: this.resourceTitle,
          description: this.resourceDescription,
          type: this.resourceType,
          file: response.url,
          visible_to_clinic: this.shareWithClinic,
          folder: this.folder ? this.folder.id : 0
        })
        .then(({ data }) => {
          if (this.resourceType !== "bulk") {
            this.clearInputs();
            this.$EventBus.$emit("resourceAdded");
            this.$EventBus.$emit("alert", {message: "Resource created."});
            this.busySaving = false;
          }
        });
    },
    addResource() {
      this.validateInputs();
      //console.log(Object.keys(this.errors));
      if (Object.keys(this.errors).length === 0) {
        this.busySaving = true;
        if (this.resourceType === "file") {
          this.$refs.resourceDropzone.dropzone.processQueue();
          //post to create resource happens from dropzone success callback for file resource
        } else if (this.resourceType === "bulk") {
          this.$refs.resourceDropzoneBulk.dropzone.options.autoProcessQueue = true;
          this.$refs.resourceDropzoneBulk.dropzone.processQueue();
        } else {
          this.$axios
            .post(process.env.VUE_APP_API_URL + "/resources/api", {
              title: this.resourceTitle,
              description: this.resourceDescription,
              type: this.resourceType,
              url: this.resourceUrl,
              inline_document: this.inlineDocument,
              visible_to_clinic: this.shareWithClinic,
              folder: this.folder ? this.folder.id : 0
            })
            .then(({ data }) => {
              this.clearInputs();
              this.$EventBus.$emit("resourceAdded");
              this.$EventBus.$emit("alert", data);
            });
        }
      }
    },
    validateInputs() {
      this.errors = [];
      if (this.resourceType === "bulk") return;

      if (!this.resourceTitle) {
        this.errors["title"] = "Title cannot be empty.";
      }
      if (!this.resourceDescription) {
        this.resourceDescription = "Add description here.";
      }
      if (!this.resourceType) {
        this.errors["type"] = "Please select a type.";
      }
      if (this.resourceType === "url") {
        if (!this.resourceUrl) {
          this.errors["url"] = "URL cannot be empty.";
        }
      }
    },
    clearInputs() {
      this.resourceTitle = "";
      this.resourceDescription = "";
      this.resourceType = "";
      this.resourceUrl = "";
      this.shareWithClinic = false;
      this.fileUploaded = false;
    },
    tabChange(tab) {
      this.$emit("tabChange", tab);
    }
  },
  components: {
    vueDropzone: vue2Dropzone
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    token() {
      return this.$store.getters['auth/token'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
    dropzoneOptions() {
      return {
        autoProcessQueue: false,
        maxFiles: 1,
        url: process.env.VUE_APP_API_URL + "/resources/upload-file",
        thumbnailWidth: 180,
        thumbnailHeight: 100,
        addRemoveLinks: true,
        headers: { Authorization: "Bearer " + this.$store.getters['auth/token'] }
      };
    },
    dropzoneOptionsBulk() {
      return {
        autoProcessQueue: false,
        maxFiles: 100,
        url: process.env.VUE_APP_API_URL + "/resources/upload-file",
        thumbnailWidth: 180,
        thumbnailHeight: 100,
        addRemoveLinks: true,
        headers: { Authorization: "Bearer " + this.$store.getters['auth/token'] }
      };
    },
  }
};
</script>

<style>
.dz-progress {
  display: none;
}
.center-bottom {
  margin: 0 auto;
  width: 40%;
}
</style>
