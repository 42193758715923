<template>
  <div
    class="modal fade"
    id="moveResourcesModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="expandedTaskModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="expandedTaskModalTitle">
            Move Resources
          </h5>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col">
              <p>
                Select folder to move {{ resourcesToMove.length }} selected
                resources into:
              </p>
              <div class="form-group">
                <select class="form-control" v-model="selectedFolder">
                  <option v-if="topLevel" :value="topLevel.folder">
                    .\ <i class="far fa-level-up-alt"></i>
                  </option>
                  <option v-for="f in folders" :value="f.id">
                    {{ f.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
            :disabled="selectedFolder == null"
            @click="moveResources()"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["topLevel", "folders", "resourcesToMove"],
  data() {
    return {
      selectedFolder: null,
    };
  },
  methods: {
    moveResources() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/resources/multi-move", {
          target_folder_id: this.selectedFolder,
          resources: this.resourcesToMove,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$parent.fetchResources();
        });
    },
  },
  computed: {},
  filters: {},
  mounted() {},
  components: {},
};
</script>

<style>
</style>
