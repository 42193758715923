<template>
  <div class="container-fluid">

    <div class="row">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <h5 class="client-tab-heading">
                  Resource <span v-if="resource">#{{ resource.id }} ({{ resource.title }})</span>
                </h5>
              </div>
              <div class="col text-end">
                <button
                  @click="tabChange()"
                  class="btn btn-outline-primary btn-sm me-2"
                >
                  <i class="far fa-align-center me-1"></i>
                  Browse Resources
                </button>
              </div>
            </div>

            <hr class="mt-2 mb-4" />

            <div v-if="!loading && resource && !viewDocument">

              <div class="row mb-3">
                <div class="col-4 mb-3 mx-auto">
                  <div class="card border-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col text-center">
                          <h6
                            class="mb-0 text-primary wrap-ellipses"
                            v-if="editTitle == false"
                          >
                            {{ resource.title }}
                            <a href="#" @click="editTitle = true"
                              ><i class="fas fa-edit"></i
                            ></a>
                          </h6>
                          <div v-if="editTitle == true">
                            <input type="textbox" v-model="resource.title" />
                            <a href="#" @click="updateTitle(resource)"
                              ><i class="fas fa-check-square"></i
                            ></a>
                          </div>

                          <p
                            v-if="resource.model === 'UrlResource'"
                            class="mb-0 bg-light mt-3 p-2"
                          >
                            <i class="fas fa-link" style="font-size: 36px"></i>
                          </p>
                          <p
                            v-if="resource.model === 'FileResource'"
                            class="mb-0 bg-light mt-3 p-2"
                          >
                            <i :class="icon" style="font-size: 36px"></i>
                          </p>
                          <p
                            class="mb-0 bg-light mt-3 p-2"
                            v-if="editDescription == false"
                          >
                            {{ resource.description }}
                            <a href="#" @click="editDescription = true"
                              ><i class="fas fa-edit"></i
                            ></a>
                          </p>

                          <div v-if="editDescription == true">
                            <input
                              type="textbox"
                              v-model="resource.description"
                            />
                            <a href="#" @click="updateDescription(resource)"
                              ><i class="fas fa-check-square"></i
                            ></a>
                          </div>

                          <button
                            @click="previewResource(resource)"
                            class="btn btn-sm btn-outline-primary mt-3 w-100"
                          >
                            <i class="fas fa-external-link-alt me-2"></i>Preview
                          </button>
                          <button
                            @click="shareResource(resource)"
                            class="btn btn-sm btn-outline-primary mt-2 w-100"
                          >
                            <i class="fa fa-share-alt me-2"></i>Share
                          </button>
                          <button
                            v-if="resource.model === 'FileResource'"
                            @click="downloadResource(resource)"
                            class="btn btn-sm btn-outline-primary mt-2 w-100"
                          >
                            <i class="fa fa-download me-2"></i>Download
                          </button>
                          <button
                            v-if="resource.model === 'UrlResource'"
                            @click="followResourceLink(resource)"
                            class="btn btn-sm btn-outline-primary mt-2 w-100"
                          >
                            <i class="fa fa-arrow-right me-2"></i>Go to URL
                          </button>
                          <button
                            @click="deleteResource(resource)"
                            class="btn btn-sm btn-outline-danger mt-2 w-100"
                          >
                            <i class="fa fa-trash text-danger me-2"></i>Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <h4>
                    Shared with Clients ({{ resource.client_resources.length }})
                  </h4>
                </div>
              </div>
              <div
                class="row mb-3"
                v-if="
                  resource.client_resources.length === 0 ||
                  !resource.client_resources
                "
              >
                <div class="col">
                  <p class="font-italic text-center">
                    Resource not shared with any clients.
                  </p>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <table
                    class="table"
                    v-if="resource.client_resources.length > 0"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Client</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                        <th scope="col">Comments</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="client_resource in resource.client_resources"
                        :key="client_resource.id"
                        class="cursor-pointer"
                        @click="goToClientInstance(client_resource)"
                      >
                        <td>{{ client_resource.client.name }}</td>
                        <td class="text-capitalize">
                          <span
                            class="badge badge-pill text-capitalize"
                            :class="
                              client_resource.read_by_client
                                ? 'bg-primary'
                                : 'bg-dark'
                            "
                          >
                            {{
                              client_resource.read_by_client ? "Read" : "Unread"
                            }}
                          </span>
                        </td>
                        <td>
                          {{ client_resource.created_at | formatDate }}
                        </td>
                        <td>{{ client_resource.comments.length }}</td>
                        <td>
                          <button
                            @click="revokeResource(client_resource, resource)"
                            class="btn btn-xs"
                          >
                            <i class="fa fa-trash text-danger"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>

            <ViewDocument v-if="!loading && viewDocument" :document="document"></ViewDocument>

            <loading v-if="loading"></loading>
          </div>
        </div>

        <share-resource
          v-if="resourceToShare"
          :resource="resourceToShare"
          @close="resourceToShare = null"
          :user="user"
        ></share-resource>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/views/components/loader/Loading";
import ShareResource from "./partials/ShareResource";
import ViewDocument from "./partials/ViewDocument";

export default {
  props: [],
  data() {
    return {
      loading: true,
      resource: null,
      resourceToShare: null,
      viewDocument: false,
      document: "",
      filetype: null,
      icon: "",
      editTitle: false,
      editDescription: false,
    };
  },
  methods: {
    fetchResource() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/resources/api/" + this.$route.params.id)
        .then(({ data }) => {
          this.resource = data;
          this.getType(this.resource);
          this.getIcon(this.filetype);
          this.loading = false;
        });
    },
    updateTitle(resource) {
      this.$axios
        .put(process.env.VUE_APP_API_URL + "/resources/api/" + resource.id, {
          title: resource.title,
          description: resource.description,
        })
        .then((data) => {
          this.$EventBus.$emit("Resource Title Updated.");
          this.editTitle = false;
        });
    },
    updateDescription(resource) {
      this.$axios
        .put(process.env.VUE_APP_API_URL + "/resources/api/" + resource.id, {
          description: resource.description,
        })
        .then((data) => {
          this.$EventBus.$emit("Resource Description Updated.");
          this.editDescription = false;
        });
    },
    previewResource(resource) {
      this.document = resource.resource.file;
      this.viewDocument = true;
    },
    shareResource(resource) {
      this.resourceToShare = resource;
    },
    downloadResource(resource) {
      window.location.replace(resource.resource.file);
    },
    followResourceLink(resource) {
      window.location.replace(resource.resource.url);
    },
    goToClientInstance(clientResource) {
      window.location.replace(
        "/clients/" +
          clientResource.client.id +
          "/client-resource/" +
          clientResource.id
      );
    },
    revokeResource(client_resource, resource) {
      var confirmed = confirm(
        "Are you sure you wish to revoke this resource from the client?"
      );
      if (confirmed) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/resources/revoke-share", {
            client_resource_id: client_resource.id,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            window.location = "/resources/" + resource.id;
          });
      }
    },
    deleteResource(resource) {
      var confirmed = confirm(
        "Are you sure you wish to delete this resource? Resource will be removed from Swandoola and clients will no longer be able to access the resource."
      );
      if (confirmed) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/resources/api/" + resource.id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            window.location = "/resources";
          });
      }
    },
    getType(resource) {
      let regFilter = /(?:\.([^.]+))?$/;
      this.filetype = regFilter.exec(resource.resource.file)[1];
    },
    getIcon(filetype) {
      switch (filetype) {
        case "doc":
        case "docx":
          this.icon = "fas fa-file-word";
          break;
        case "xls":
        case "xlsx":
          this.icon = "fas fa-file-excel";
          break;
        case "pdf":
          this.icon = "fas fa-file-pdf";
          break;
        case "jpg":
        case "jpeg":
        case "png":
          this.icon = "fas fa-file-image";
          break;
        case "mp4":
          this.icon = "fas fa-file-video";
          break;
        default:
          this.icon = "fas fa-file";
      }
    },
    tabChange() {
      this.$router.push('/resources')
    }
  },
  mounted() {
    this.fetchResource();
    const vm = this;
    this.$EventBus.$on("sharedWithClient", function () {
      vm.fetchResource();
    });
    this.$EventBus.$on("sharedWithTag", function () {
      vm.fetchResource();
    });
    this.$EventBus.$on("viewDocumentClose", function () {
      vm.viewDocument = false;
    });
  },
  filters: {
    formatDate(date) {
      return moment(date).fromNow();
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  components: { Loading, ShareResource, ViewDocument },
};
</script>

<style>
</style>
