<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card card-default">
          <div class="card-body bg-white">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <h4 class="mb-0">Advanced Search</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 offset-md-3 d-flex justify-content-center">
                  <select
            class="form-control mb-3"
            v-model="searchType"
          >
            <option value>Select what to search for</option>
            <option value="rname">Resource Name</option>
            <option value="rdesc">Resource Description</option>
            <option value="shared">Shared With Client</option>
          </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 offset-md-3 d-flex justify-content-center">

          <input
            type="text"
            placeholder="Search for Resource Name"
            class="form-control mb-3"
            v-model="searchName"
            v-if="searchType == 'rname'"
          />

          <input
            type="text"
            placeholder="Search for Resource Description"
            class="form-control mb-3"
            v-model="searchDesc"
            v-if="searchType == 'rdesc'"
          />

          <input
            type="text"
            placeholder="Search for Client"
            class="form-control mb-3"
            v-model="searchClient"
            v-if="searchType == 'shared'"
          />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
        searchType: "",
        searchDesc: "",
        searchClient: "",

    };
  },
  methods: {
  },
  created: function () {
  },
};
</script>