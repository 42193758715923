var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('p',{staticClass:"mb-1"},[_vm._v("Search client by name")]),_vm._m(0),_c('div',{staticClass:"client-assign dropdown cursor-pointer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{attrs:{"id":"searchClient"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.clientSearch),expression:"clientSearch"}],staticClass:"form-control form-control-sm me-1",attrs:{"type":"text","id":"searchClientsInput","placeholder":"Search for a Client"},domProps:{"value":(_vm.clientSearch)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.clientSearch=$event.target.value},_vm.searchClients]}})]),_c('div',{staticClass:"col-auto my-auto"},[_c('button',{staticClass:"btn btn-sm btn-light",on:{"click":_vm.clearClientSearch}},[_c('i',{staticClass:"far fa-times"})])])]),(
                _vm.clientSearchInProgress ||
                (!_vm.firstClientSearch &&
                  !_vm.clientSearchInProgress &&
                  _vm.clientSearchResults.length === 0) ||
                _vm.clientSearchResults.length > 0
              )?_c('div',{staticClass:"row mt-3",attrs:{"id":"clientSearchContainer"}},[_c('div',{staticClass:"col"},[_c('busy',{attrs:{"visible":_vm.clientSearchInProgress}}),(
                    !_vm.firstClientSearch &&
                    !_vm.clientSearchInProgress &&
                    _vm.clientSearchResults.length === 0
                  )?_c('div',{staticClass:"text-center pe-5",attrs:{"id":"clientSearchNoResults"}},[_vm._m(1),_c('span',{staticClass:"font-italic"},[_vm._v("No results, please try a different search term")])]):_vm._e(),(_vm.clientSearchResults.length > 0)?_c('div',{attrs:{"id":"clientSearchResults"}},[_c('ul',{staticClass:"list-group"},_vm._l((_vm.clientSearchResults),function(client){return _c('li',{staticClass:"list-group-item cursor-pointer small fw-bold py-2",on:{"click":function($event){return _vm.selectClient(client)}}},[_vm._v(" "+_vm._s(client.first_name)+" "+_vm._s(client.last_name)+" (#"+_vm._s(client.id)+") ")])}),0)]):_vm._e()],1)]):_vm._e()])])])]),_c('hr'),_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v("Search client by tags")]),_vm._m(2),_c('div',{staticClass:"client-assign dropdown cursor-pointer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{attrs:{"id":"searchTag"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tagSearch),expression:"tagSearch"}],staticClass:"form-control form-control-sm me-1",attrs:{"type":"text","id":"searchTagsInput","placeholder":"Search for a tag"},domProps:{"value":(_vm.tagSearch)},on:{"keyup":_vm.searchTags,"input":function($event){if($event.target.composing){ return; }_vm.tagSearch=$event.target.value}}})]),_c('div',{staticClass:"col-auto my-auto"},[_c('button',{staticClass:"btn btn-sm btn-light",on:{"click":_vm.clearTagSearch}},[_c('i',{staticClass:"far fa-times"})])])]),(
                  _vm.tagSearchInProgress ||
                  (!_vm.firstTagSearch &&
                    !_vm.tagSearchInProgress &&
                    _vm.tagSearchResults.length === 0) ||
                  _vm.tagSearchResults.length > 0
                )?_c('div',{staticClass:"row mt-3",attrs:{"id":"tagSearchContainer"}},[_c('div',{staticClass:"col"},[_c('busy',{attrs:{"visible":_vm.tagSearchInProgress}}),(
                      !_vm.firstTagSearch &&
                      !_vm.tagSearchInProgress &&
                      _vm.tagSearchResults.length === 0
                    )?_c('div',{staticClass:"text-center pe-5",attrs:{"id":"tagSearchNoResults"}},[_vm._m(3),_c('span',{staticClass:"font-italic"},[_vm._v("No results, please try a different search term")])]):_vm._e(),(_vm.tagSearchResults.length > 0)?_c('div',{attrs:{"id":"tagSearchResults"}},[_c('ul',{staticClass:"list-group"},_vm._l((_vm.tagSearchResults),function(tag){return _c('li',{staticClass:"list-group-item cursor-pointer small fw-bold py-2",on:{"click":function($event){return _vm.selectTag(tag)}}},[_vm._v(" "+_vm._s(tag)+" ")])}),0)]):_vm._e()],1)]):_vm._e()])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block bg-light px-2 mb-2"},[_c('small',[_c('i',{staticClass:"far fa-info-circle me-1 text-primary"}),_vm._v(" Share resource with an individual client. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-1"},[_c('i',{staticClass:"fa fa-2x fa-times-circle text-primary"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block bg-light px-2 mb-2"},[_c('small',[_c('i',{staticClass:"far fa-info-circle me-1 text-primary"}),_vm._v(" Resource will be shared with all clients with a matching tag. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-1"},[_c('i',{staticClass:"fa fa-2x fa-times-circle text-primary"})])}]

export { render, staticRenderFns }