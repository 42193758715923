<template>
  <div class="container-fluid">

    <div class="row">
      <div class="col-md-12">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-auto my-auto">
                <h5 class="client-tab-heading">Resources</h5>
              </div>
              <div class="col text-end">
                <button
                  @click="tabChange('folder-tab')"
                  class="btn btn-outline-primary btn-sm me-2"
                >
                  <i class="fas fa-upload me-1"></i>
                  Add Folder
                </button>

                <button
                  @click="tabChange('upload-tab')"
                  class="btn btn-outline-primary btn-sm"
                >
                  <i class="fas fa-folder me-1"></i>
                  Add Resources
                </button>
              </div>
            </div>

            <hr class="mt-2 mb-4" />

            <div class="row mb-4">
              <div class="col-auto my-auto d-flex align-items-center form-inline">
                <select
                  class="form-control form-control-sm me-2"
                  v-model="searchType"
                >
                  <option value="rname">Resource Name</option>
                  <option value="rdesc">Resource Description</option>
                </select>
                <input
                  type="text"
                  v-model="search"
                  class="form-control form-control-sm me-2"
                  autocomplete="offington"
                  placeholder="Enter search query"
                />
                <button
                  class="btn btn-sm btn-light d-inline"
                  @click="search = ''"
                >
                  <i class="far fa-times"></i>
                </button>
              </div>

              <div class="col-auto ms-auto d-flex align-items-center form-inline">
                <button
                  class="btn btn-sm btn-outline-dark me-2"
                  :class="desc == false ? 'active' : ''"
                  @click="desc = false"
                >
                  <i class="far fa-sort-size-up"></i>
                </button>
                <button
                  class="btn btn-sm btn-outline-dark me-2"
                  :class="desc == true ? 'active' : ''"
                  @click="desc = true"
                >
                  <i class="far fa-sort-size-down"></i>
                </button>

                <div class="dropdown me-2">
                  <button
                    class="btn btn-light btn-sm dropdown-toggle"
                    type="button"
                    id="sortingDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="far fa-sort me-2"></i>
                    <span class="text-capitalize">{{ sorting | formatLabel }}</span>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="sortingDropdown">
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="sortBy('id')"
                    >
                      ID
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="sortBy('title')"
                    >
                      Name
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="sortBy('model')"
                    >
                      Type
                    </button>
                    <button
                      class="dropdown-item"
                      type="button"
                      @click="sortBy('created_at')"
                    >
                      Uploaded
                    </button>
                  </div>
                </div>

                <div class="btn-group">
                  <button
                    class="me-2"
                    :class="
                      viewGrid == true
                        ? 'btn btn-sm btn-primary'
                        : 'btn btn-sm btn-outline-primary'
                    "
                    @click="viewGrid = true"
                  >
                    <i class="far fa-th-large me-1"></i>Grid
                  </button>
                  <button
                    :class="
                      viewGrid == false
                        ? 'btn btn-sm btn-primary'
                        : 'btn btn-sm btn-outline-primary'
                    "
                    @click="viewGrid = false"
                  >
                    <i class="far fa-list me-1"></i>List
                  </button>
                </div>
              </div>
            </div>

            <move-files-modal :topLevel="folder" :folders="displayFolders" :resourcesToMove="selectedResources"/>

            <div class="row mb-4">
              <div class="col-md-6 d-flex align-items-center" v-if="selectedResources.length > 0">
                <label class="me-2">Selected Items ({{ selectedResources.length }}): </label>
                <button
                  class="btn btn-primary btn-sm me-1"
                  @click="shareMultipleResources()"
                >
                  <i class="fa fa-share-alt me-2"></i>Share
                </button>
                <button
                  class="btn btn-primary btn-sm me-1" data-toggle="modal" data-target="#moveResourcesModal"
                >
                  <i class="fas fa-arrows-alt me-2"></i>Move
                </button>
              </div>
              <div class="col-md-6 d-flex align-items-center" v-else></div>
              <div class="col-md-6 d-flex align-items-center justify-content-end" v-if="folder">
                <label class="me-2">Current folder: {{ folder.title }}</label>
                <button
                  class="btn btn-outline-primary"
                  @click="upLevel()"
                  id="parentDrop"
                  @dragover.prevent
                  @drop.stop="upLevelDrop($event)"
                  @dragenter.stop="upLevelDragEnter()"
                  @dragleave.stop="upLevelDragLeave()"
                >
                  <i class="far fa-level-up-alt"></i>
                </button>
              </div>
            </div>

            <table class="table table-hover" v-if="!viewGrid">
              <thead>
                <tr>
                  <th scope="col">
                    <span class="cursor-pointer p-1 text-primary"
                      ><i class="far fa-check"></i
                    ></span>
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Type</th>
                  <th scope="col">Uploaded</th>
                  <th></th>
                </tr>
              </thead>
              <tbody style="overflow-y: scroll">
                <tr
                  v-for="r in displayFolders"
                  :key="r.id"
                  class="cursor-pointer"
                  :id="'folder-row-' + r.id"
                  draggable="true"
                  @dragover.prevent
                  @dragstart="folderDragStart($event, r)"
                  @drop.stop="folderDrop($event, r)"
                  @dragenter.stop="folderDragEnter($event, r)"
                  @dragleave.stop="folderDragLeave($event, r)"
                >
                  <td class="me-1" style="width: 5%;"><i class="fa fa-folder text-primary"></i></td>
                  <td @click="goToResource(r)" class="text-capitalize me-1" style="width: 20%;">
                    {{ r.title }}
                  </td>
                  <td @click="goToResource(r)" class="me-1" style="width: 30%;">{{ r.description }}</td>
                  <td @click="goToResource(r)" class="me-1" style="width: 15%;">{{ r.model | formatType }}</td>
                  <td @click="goToResource(r)" class="me-1" style="width: 20%;">{{ r.created_at | formatDate }}</td>
                  <td style="width: 10%;">
                    <button
                      @click="deleteResource(r)"
                      class="btn btn-xs btn-outline-primary"
                    >
                      <i class="fa fa-trash text-danger"></i>
                    </button>
                  </td>
                </tr>
                <tr
                  v-for="r in displayResources"
                  :key="r.id"
                  class="cursor-pointer"
                  draggable="true"
                  @dragstart="fileDragStart($event, r)"
                >
                  <td style="width: 5%;">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :value="r"
                        v-model="selectedResources"
                      />
                    </div>
                  </td>
                  <td @click="goToResource(r)" class="text-capitalize" style="width: 20%;">
                    {{ r.title }}
                  </td>
                  <td @click="goToResource(r)" style="width: 30%;">{{ r.description }}</td>
                  <td @click="goToResource(r)" style="width: 15%;">{{ r.model | formatType }}</td>
                  <td @click="goToResource(r)" style="width: 20%;">{{ r.created_at | formatDate }}</td>
                  <td style="width: 10%;">
                    <button
                      @click="shareResource(r)"
                      class="btn btn-xs btn-outline-primary me-2"
                    >
                      <i class="fa fa-share-alt"></i>
                    </button>
                    <button
                      @click="deleteResource(r)"
                      class="btn btn-xs btn-outline-primary"
                    >
                      <i class="fa fa-trash text-danger"></i>
                    </button>
                  </td>
                </tr>
                <tr v-if="!loading && !displayFolders.length && !displayResources.length">
                  <td colspan="6" class="my-2">No resources found.</td>
                </tr>
              </tbody>
            </table>

            <div class="card border-0 bg-light" v-else>
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col-2 mb-3" v-for="r in displayFolders" :key="r.id">
                    <folder-resource-card
                      :resource="r"
                      @shared="shareResource($event)"
                      @deleted="deleteResource($event)"
                    ></folder-resource-card>
                  </div>
                  <div class="col-2 mb-3" v-for="r in displayResources" :key="r.id">
                    <resource-card
                      :resource="r"
                      @shared="shareResource($event)"
                      @deleted="deleteResource($event)"
                    ></resource-card>
                  </div>
                </div>
              </div>
            </div>

            <loading v-if="loading"></loading>
          </div>
        </div>
      </div>
    </div>

    <share-resource
      v-if="resourceToShare"
      :resource="resourceToShare"
      :user="user"
      @close="resourceToShare = null"
    ></share-resource>
    <ShareResources
      v-if="resourcesToShare.length > 0"
      :resources="resourcesToShare"
      :user="user"
      @close="resourcesToShare = []"
    />
  </div>
</template>

<script>
import ShareResource from "./partials/ShareResource";
import ShareResources from "./partials/ShareResources";
import ResourceCard from "./partials/ResourceCard";
import FolderResourceCard from "@/views/areas/resources/partials/FolderResourceCard";
import MoveFilesModal from './partials/MoveFilesModal.vue';
import Loading from "@/views/components/loader/Loading";

export default {
  props: ["folder"],
  data() {
    return {
      resources: [],
      desc: false,
      sorting: null,
      search: null,
      resourceToShare: null,
      resourcesToShare: [],
      viewGrid: false,
      searchType: "rname",
      selectedResources: [],
      loading: false
    };
  },
  methods: {
    sortBy(sortKey) {
      this.sorting = sortKey;
    },
    shareResource(resource) {
      this.resourceToShare = resource;
    },
    shareMultipleResources() {
      this.resourcesToShare = this.selectedResources;
    },
    fetchResources() {
      this.loading = true;

      var vm = this;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/resources/api")
        .then(({ data }) => {
          vm.resources = data;
          this.selectedResources = [];
          this.loading = false;
        });
    },
    deleteResource(resource) {
      var confirmed = confirm(
        "Are you sure you wish to delete this resource? Resource will be removed from Swandoola and clients will no longer be able to access the resource."
      );
      if (confirmed) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/resources/api/" + resource.id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchResources();
          });
      }
    },
    goToResource(r) {
      if (r.model == "FolderResource") {
        this.folder = r;
        this.$emit("update:folder", this.folder);
      } else {
        window.location = "/resources/" + r.id;
      }
    },
    upLevel() {
      if (this.folder.folder == null || this.folder.folder == 0) {
        this.folder = null;
      } else {
        this.folder = this.resources.find(
          (item) => item.id == this.folder.folder
        );
      }

      this.$emit("update:folder", this.folder);
    },
    fileDragStart(event, file) {
      event.dataTransfer.setData(
        "text/plain",
        JSON.stringify({
          type: "file",
          id: file.id,
          folder: this.folder ? this.folder.folder : 0,
        })
      );
    },
    folderDragStart(event, folder) {
      event.dataTransfer.setData(
        "text/plain",
        JSON.stringify({
          type: "folder",
          id: folder.id,
          folder: this.folder ? this.folder.folder : 0,
        })
      );
    },
    folderDragEnter(event, folder) {
      $("#folder-row-" + folder.id).addClass("bg-primary text-white");
    },
    folderDragLeave(event, folder) {
      $("#folder-row-" + folder.id).removeClass("bg-primary text-white");
    },
    folderDrop(event, folder) {
      $("#folder-row-" + folder.id).removeClass("bg-primary text-white");
      const evtData = JSON.parse(event.dataTransfer.getData("text"));
      console.log(
        "Move " +
          evtData.type +
          " #" +
          evtData.id +
          " to folder " +
          folder.title
      );

      if (folder.id !== evtData.id) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/resources/move", {
            target_folder_id: folder.id,
            source_id: evtData.id,
          })
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchResources();
          });
      }
    },
    upLevelDragEnter() {
      $("#parentDrop").addClass("bg-primary");
    },
    upLevelDragLeave() {
      $("#parentDrop").removeClass("bg-primary");
    },
    upLevelDrop(event) {
      $("#parentDrop").removeClass("bg-primary");
      const evtData = JSON.parse(event.dataTransfer.getData("text"));
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/resources/move", {
          target_folder_id: evtData.folder,
          source_id: evtData.id,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchResources();
        });
    },
    tabChange(tab) {
      this.$emit("tabChange", tab);
    }
  },
  filters: {
    formatLabel(str) {
      if (!str) {
        return "Sorting";
      } else {
        return str.replace(/_/g, " ");
      }
    },
    formatDate(date) {
      return date ? moment(date).format("Do MMM YYYY") : "Not Set";
    },
    formatType(model) {
      return model.replace("Resource", "");
    },
  },
  mounted() {
    this.fetchResources();
    this.$EventBus.$on("resourceAdded", this.fetchResources);
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    resourcesInFolder: function () {
      let folder_id = this.folder ? this.folder.id : 0;
      return this.resources.filter((item) => {
        return folder_id === item.folder && item.model !== "FolderResource";
      });
    },
    foldersInFolder: function () {
      let folder_id = this.folder ? this.folder.id : 0;
      return this.resources.filter((item) => {
        return folder_id === item.folder && item.model === "FolderResource";
      });
    },
    orderedResources: function () {
      if (this.desc == false) {
        return _.orderBy(this.resourcesInFolder, this.sorting);
      } else {
        return _.orderBy(this.resourcesInFolder, this.sorting)
          .slice()
          .reverse();
      }
    },
    orderedFolders: function () {
      if (this.desc == false) {
        return _.orderBy(this.foldersInFolder, this.sorting);
      } else {
        return _.orderBy(this.foldersInFolder, this.sorting).slice().reverse();
      }
    },
    displayFolders() {
      if (this.search && this.search.length > 0) {
        return this.orderedFolders.filter((item) => {
          if (this.searchType === "rname") {
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
          } else if (this.searchType === "rdesc") {
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) => item.description.toLowerCase().includes(v));
          }
        });
      } else {
        return this.orderedFolders;
      }
    },
    displayResources() {
      if (this.search && this.search.length > 0) {
        return this.orderedResources.filter((item) => {
          if (this.searchType === "rname") {
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
          } else if (this.searchType === "rdesc") {
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) => item.description.toLowerCase().includes(v));
          }
        });
      } else {
        return this.orderedResources;
      }
    }
  },
  components: {
    FolderResourceCard,
    ResourceCard,
    ShareResource,
    ShareResources,
    MoveFilesModal,
    Loading
  },
};
</script>

<style>
</style>
