<template>
  <div>
    <div>
      <p class="mb-1">Search client by name</p>
      <div class="d-block bg-light px-2 mb-2">
        <small>
          <i class="far fa-info-circle me-1 text-primary"></i>
          Share resource with an individual client.
        </small>
      </div>
      <div class="client-assign dropdown cursor-pointer">
        <div class="row">
          <div class="col">
            <div id="searchClient">
              <div class="row">
                <div class="col">
                  <input
                    type="text"
                    id="searchClientsInput"
                    placeholder="Search for a Client"
                    v-model="clientSearch"
                    class="form-control form-control-sm me-1"
                    @input="searchClients"
                  />
                </div>
                <div class="col-auto my-auto">
                  <button
                    class="btn btn-sm btn-light"
                    @click="clearClientSearch"
                  >
                    <i class="far fa-times"></i>
                  </button>
                </div>
              </div>

              <div
                class="row mt-3"
                id="clientSearchContainer"
                v-if="
                  clientSearchInProgress ||
                  (!firstClientSearch &&
                    !clientSearchInProgress &&
                    clientSearchResults.length === 0) ||
                  clientSearchResults.length > 0
                "
              >
                <div class="col">
                  <busy :visible="clientSearchInProgress" />
                  <div
                    v-if="
                      !firstClientSearch &&
                      !clientSearchInProgress &&
                      clientSearchResults.length === 0
                    "
                    id="clientSearchNoResults"
                    class="text-center pe-5"
                  >
                    <p class="mb-1">
                      <i class="fa fa-2x fa-times-circle text-primary"></i>
                    </p>
                    <span class="font-italic"
                      >No results, please try a different search term</span
                    >
                  </div>
                  <div
                    v-if="clientSearchResults.length > 0"
                    id="clientSearchResults"
                  >
                    <ul class="list-group">
                      <li
                        v-for="client in clientSearchResults"
                        @click="selectClient(client)"
                        class="list-group-item cursor-pointer small fw-bold py-2"
                      >
                        {{ client.first_name }} {{ client.last_name }} (#{{
                          client.id
                        }})
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <p class="mb-0">Search client by tags</p>
        <div class="d-block bg-light px-2 mb-2">
          <small>
            <i class="far fa-info-circle me-1 text-primary"></i>
            Resource will be shared with all clients with a matching tag.
          </small>
        </div>

        <div class="client-assign dropdown cursor-pointer">
          <div class="row">
            <div class="col">
              <div id="searchTag">
                <div class="row">
                  <div class="col">
                    <input
                      type="text"
                      id="searchTagsInput"
                      placeholder="Search for a tag"
                      v-model="tagSearch"
                      class="form-control form-control-sm me-1"
                      v-on:keyup="searchTags"
                    />
                  </div>
                  <div class="col-auto my-auto">
                    <button
                      class="btn btn-sm btn-light"
                      @click="clearTagSearch"
                    >
                      <i class="far fa-times"></i>
                    </button>
                  </div>
                </div>

                <div
                  class="row mt-3"
                  id="tagSearchContainer"
                  v-if="
                    tagSearchInProgress ||
                    (!firstTagSearch &&
                      !tagSearchInProgress &&
                      tagSearchResults.length === 0) ||
                    tagSearchResults.length > 0
                  "
                >
                  <div class="col">
                    <busy :visible="tagSearchInProgress" />
                    <div
                      v-if="
                        !firstTagSearch &&
                        !tagSearchInProgress &&
                        tagSearchResults.length === 0
                      "
                      id="tagSearchNoResults"
                      class="text-center pe-5"
                    >
                      <p class="mb-1">
                        <i class="fa fa-2x fa-times-circle text-primary"></i>
                      </p>
                      <span class="font-italic"
                        >No results, please try a different search term</span
                      >
                    </div>
                    <div
                      v-if="tagSearchResults.length > 0"
                      id="tagSearchResults"
                    >
                      <ul class="list-group">
                        <li
                          v-for="tag in tagSearchResults"
                          @click="selectTag(tag)"
                          class="list-group-item cursor-pointer small fw-bold py-2"
                        >
                          {{ tag }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Busy from '../../../../components/Busy.vue';
export default {
  components: { Busy },
  props: ["model"],
  data() {
    return {
      clientSearch: "",
      tagSearch: "",
      clientSearchInProgress: false,
      clientSearchResults: [],
      firstClientSearch: true,
      tagSearchInProgress: false,
      tagSearchResults: [],
      firstTagSearch: true,
    };
  },
  methods: {
    searchClients() {
      this.clientSearchResults = [];
      if (this.clientSearch.length > 2) {
        this.firstClientSearch = false;
        this.clientSearchInProgress = true;
        const vm = this;
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/clients/search-by-name", {
            search_term: this.clientSearch,
            show_inactive: 0
          })
          .then(function (response) {
            vm.clientSearchInProgress = false;
            vm.clientSearchResults = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    searchTags() {
      this.tagSearchResults = [];
      if (this.tagSearch.length > 2) {
        this.firstTagSearch = false;
        this.tagSearchInProgress = true;
        const vm = this;
        this.$axios
          .post(
            process.env.VUE_APP_API_URL + "/autocomplete/tags/available-tags",
            {
              search: this.tagSearch,
            }
          )
          .then(function (response) {
            vm.tagSearchResults = [];
            vm.tagSearchInProgress = false;
            console.log(response);
            Object.keys(response.data).forEach((key) => {
              let tag = response.data[key];
              let tagName = JSON.parse(tag.name);
              vm.tagSearchResults.push(tagName.en);
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    clearClientSearch() {
      this.clientSearch = "";
      this.clientSearchResults = [];
      this.firstClientSearch = true;
    },
    clearTagSearch() {
      this.tagSearch = "";
      this.tagSearchResults = [];
      this.firstTagSearch = true;
    },
    selectClient(client) {
      this.$emit("assigned", client);
      this.clearClientSearch();
    },
    selectTag(tag) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/clients/fetch-by-tag", {
          tag: tag,
        })
        .then(({ data }) => {
          for (let i = 0; i < data.clients.length; i++) {
            this.$emit("assigned", data.clients[i]);
          }
          this.clearTagSearch();
        });
    },
  },
};
</script>

<style>
.client-assign .dropdown-menu {
  max-height: 450px;
  width: 350px;
  overflow-y: auto;
}
</style>