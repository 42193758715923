<template>
  <div class="card border-0 shadow-sm mb-3 h-100">
    <div class="card-header mb-2 pb-0">
      <div class="row mb">
        <div class="col">
          <span><h1 class="text-primary h4 mb-1 d-inline">Resources</h1></span>
        </div>
      </div>
    </div>
    <div class="card-body h-100 p-0">
      <div class="scollable-nav">
        <div class="nav flex-column nav-pills my-3">
          <a
            href="#files-tab"
            data-toggle="tab"
            class="nav-link"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="far fa-align-center text-primary"></i>
              </div>
              <div class="col my-auto">Browse Resources</div>
            </div>
          </a>
          <a
            href="#view-tab"
            data-toggle="tab"
            class="nav-link active"
            v-if="resource != null"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="fas fa-eye text-primary"></i>
              </div>
              <div class="col my-auto">Resource [#{{resource.id}}]</div>
            </div>
          </a>
          <a
            href="#upload-tab"
            data-toggle="tab"
            class="nav-link"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="fas fa-upload text-primary"></i>
              </div>
              <div class="col my-auto">Add Resources</div>
            </div>
          </a>
          <a
            href="#folder-tab"
            data-toggle="tab"
            class="nav-link"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="fas fa-folder text-primary"></i>
              </div>
              <div class="col my-auto">Add Folder</div>
            </div>
          </a>
          <!--
            Notes: To be replaced by minified filter on browse resources tab.
          <a
            href="#search-tab"
            data-toggle="tab"
            class="nav-link"
          >
            <div class="row">
              <div class="col-2 text-center my-auto">
                <i class="fas fa-upload text-primary"></i>
              </div>
              <div class="col my-auto">Advanced Search</div>
            </div>
          </a>
          -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: ["resource"],
};
</script>
<style scoped>

.nav-link.active > div > div > i {
  color: white !important;
}
.nav-link:hover {
  background-color: #ddd;
}
</style>