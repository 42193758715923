<template>
    <div class="card border-0 cursor-pointer"
         draggable="true"
         @dragstart="fileDragStart"
    >
        <div class="card-body">
            <div class="row">
                <div class="col text-center">
                    <h6 class="mb-0 text-primary wrap-ellipses">{{ resource.title }}</h6>
                    <p v-if="resource.model === 'UrlResource'" class="mb-0 bg-light mt-3 p-2"><i class="text-primary fas fa-link" style="font-size: 36px;"></i></p>
                    <p v-if="resource.model === 'FileResource'" class="mb-0 bg-light mt-3 p-2">
                        <i :class="'text-primary doc-icon ' + icon"></i>
                    </p>
                    <div class="row mt-2 px-2">
                        <div class="col-4 px-1">
                            <a :href="'/resources/' + resource.id" class="btn btn-xs btn-outline-primary w-100"><i class="fa fa-eye"></i></a>
                        </div>
                        <div class="col-4 px-1">
                            <button @click="shareResource(resource)" class="btn btn-xs btn-outline-primary w-100"><i class="fa fa-share-alt"></i></button>
                        </div>
                        <div class="col-4 px-1">
                            <button @click="deleteResource(resource)" class="btn btn-xs btn-outline-primary w-100"><i class="fa fa-trash text-danger"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: ["resource"],
    data: function () {
      return {
      };
    },
    computed: {
      icon() {
        let regFilter = /(?:\.([^.]+))?$/;
        const filetype = regFilter.exec(this.resource.resource.file)[1];
        switch (filetype) {
          case "doc":
          case "docx":
            return "fas fa-file-word";
          case "xls":
          case "xlsx":
            return "fas fa-file-excel";
          case "pdf":
            return "fas fa-file-pdf";
          case "jpg":
          case "jpeg":
          case "png":
            return "fas fa-file-image";
          case "mp4":
            return "fas fa-file-video";
          default:
            return "fas fa-file";
        }
      },
    },
    methods: {
      shareResource(resource) {
        this.$emit('shared', resource);
      },
      deleteResource(resource) {
        this.$emit('deleted', resource);
      },
      fileDragStart(event) {
        event.dataTransfer.setData(
            "text/plain",
            JSON.stringify({
              id: this.resource.id,
              folder: this.$parent.folder ? this.$parent.folder.folder : 0,
            })
        );
      },
    },
  };
</script>

<style scoped>
.doc-icon {
    font-size: 36px;
}
</style>
